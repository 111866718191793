import React from 'react'
import './Answer.scss'

export default function Answer(props) {
    return (
        <div>
            <input 
                style={{display: 'none'}}
                type="radio" 
                id={props.id} 
                name="answer" 
                value={props.id}
                checked={props.checked}
                onChange={() => props.selectAnswer(props.id)}
                disabled={props.disabled}
            />
            <label className="Answer_label"
                htmlFor={props.id}
                style={{backgroundColor: props.checked ? '#A95600' : 'inherit'}}
            >
                {props.band} &#8211; {props.song}
            </label>
        </div>
    )
}
