import React, { Component } from 'react'
import * as Sentry from '@sentry/browser'

export default class ErrorBoundary extends Component {
    state = { hasError: false }

    static getDerivedStateFromError(error) {
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        Sentry.withScope((scope) => {
            scope.setExtras(errorInfo)
            Sentry.captureException(error)
        })
    }

    render() {
        if (this.state.hasError) {
            return (
                <div style={{textAlign: 'center'}}>
                    <h1>An error occurred :(</h1>
                    <p>Please check your Internet connection and reload.</p>
                </div>
            )
        }
        return this.props.children
    }
}


//https://www.smashingmagazine.com/2020/06/react-error-handling-reporting-error-boundary-sentry/
