import React from 'react'
import './Score.scss'

export default function Score(props) {
    return (
        <div className="Score">
            <span>
                Level score: {props.correctAnswersLevelTotal}
            </span>

            <div 
                className={props.questionScore === null ? 'noop' : 'animated-score'}
                style= {
                    props.questionScore === -1 || props.questionScore === 0
                    ? {color: 'red'}
                    : {color: 'green'}
                }
            >
                {
                    props.questionScore === 1 || props.questionScore === 0
                    ? '+' + props.questionScore 
                    : props.questionScore
                }
            </div>
        </div>
    )
}
