import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import ErrorBoundary from './components/ErrorBoundary'
import './index.scss'
import App from './App'

/*
Error logging
Vercel defines BITBUCKET_COMMIT_SHA, which displays as the release name in Sentry.
BITBUCKET_COMMIT_SHA is aliased as REACT_APP_BITBUCKET_COMMIT_SHA in package.json
(create-react-app insists that env vars other than NODE_ENV start with REACT_APP_)
*/
Sentry.init({
    dsn: 'https://2567f340ad9c450094abe0a99fd6ac2b@o348088.ingest.sentry.io/5274863',
    release: 'wordsOfMetal@' + process.env.REACT_APP_BITBUCKET_COMMIT_SHA
})
// if (process.env.NODE_ENV !== 'production') {
//     Sentry.getCurrentHub().getClient().getOptions().enabled = false
// }

ReactDOM.render(
    <React.StrictMode>
        <ErrorBoundary>
            <App />
        </ErrorBoundary> 
    </React.StrictMode>,
    document.getElementById('root')
)

