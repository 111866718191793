import React from 'react'
import './LevelCompleted.scss'

export default function LevelCompleted(props) {
    const percentageScore = (props.correctAnswersLevelTotal / props.numOfQuestions) * 100
    const userDidBeatLevel = percentageScore > 80

    let heading
    let img
    if (userDidBeatLevel) {
        heading = <>Level {props.levelNumber} completed!</>
        img = <img src={props.levelSuccessImageURL} alt="The view of the stage during a metal gig" />
    } else {
        heading = <>On level {props.levelNumber}&hellip;</>
        img = <img src={props.failureImageURL} alt="A women looking unhappy" />
    }

    return (
        <>
            <div className="LevelCompleted">
                <h1>{heading}</h1>
                
                <p>You scored {props.correctAnswersLevelTotal} out of {props.numOfQuestions}</p>
                
                {!userDidBeatLevel &&
                    <p>Remember, a point gets deducted for every incorrect answer!</p>
                }

                {img}

                {userDidBeatLevel
                    ? <button onClick={props.loadNextLevel}>Next level</button>
                    : <>
                        <p>You need a better score to progress to the next level!</p>
                        <p>5 out of 6 is needed</p>
                        <button onClick={props.restartLevel}>Restart level</button>
                    </>
                }

                {/* <p className="hashtag">&#35;WordsOfMetal</p> */}
            </div>

            <footer>
                Kick-ass metal: 
                <a 
                    href="https://reduceright.com"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Reduce Right
                </a>
            </footer>
        </>
    )
}
