import React from 'react'
import Answer from './Answer'

export default function Answers(props) {
    const answers = props.choices && props.choices.map(
        choice => 
            <Answer 
                key={choice.id} 
                {...choice} 
                checked={props.userSelectionId === choice.id}
                selectAnswer={props.selectAnswer}
                disabled={props.disabled}
            />
    )
    return (
        <div>
            {answers}
        </div>
    )
}


/*
export default class Answers extends React.Component {
    state = {}

    // constructor(props) {
    //     super(props)
    // }

    render () {
        const answers = this.props.choices.map(choice => 
            <Answer {...choice} />
        )
        return (
            <div>
                {answers}
            </div>
        )
    }
}
*/
