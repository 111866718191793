import React from 'react'
import './Welcome.scss'

export default function Welcome(props) {
    return (
        <div className="Welcome">
            <h1>Think you know metal lyrics?</h1>
            <p>Select which song the lyrics come from.</p>
            <p>6 levels of 6 questions.</p>
            <p>To discourage guessing, wrong answers get points deducted!</p>
            <button onClick={props.startGame}>Go</button>
        </div>
    )
}
