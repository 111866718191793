import React from 'react'
import './VideoEmbed.scss'

export default function VideoEmbed(props) {
    return (
        <div className="VideoEmbed">
            <iframe 
                title="youtube"
                src={'https://www.youtube.com/embed/' + props.youtube}
                frameborder="0" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen>
            </iframe>
        </div>
    )
}
