import React, { Component } from 'react'
import * as firebase from 'firebase/app'
import 'firebase/firestore'
import * as Sentry from '@sentry/browser'
import { randomiseArrayOrder } from './helpers'
import Welcome from './components/Welcome'
import Question from './components/Question'
import Answers from './components/Answers'
import ConfirmAnswerBtn from './components/ConfirmAnswerBtn'
import LevelResultMsg from './components/LevelResultMsg'
import LevelCompleted from './components/LevelCompleted'
import GameCompleted from './components/GameCompleted'
import Score from './components/Score'
import Timer from './components/Timer'
import VideoEmbed from './components/VideoEmbed'
import LoadingScreen from './components/LoadingScreen'
import './App.scss'

const config = {
    questionsPerLevel: 6, //TODO UPDATE to 6 !!
    //Sixes are a metal thing! Hence a static number of questions and levels
    numberOfLevels: 6, //TODO UPDATE to 6 !!
    // levelTimeLimitsAndTimerColors: [[11, '0085C0'], [10, '0046c0'], [9, '3200c0'], [8, 'c00048'], [7, 'c00018'], [6, 'f40707']], //0085C0, 0046c0  3200c0  c00071  c00048  c0002a  c00018  f40707
    levelTimeLimitsAndTimerColors: [[12, '0085C0'], [11, '0085C0'], [10, '0085C0'], [9, '0085C0'], [8, '0085C0'], [7, '0085C0']],
    failureImage: 'failureImage.jpg',
    successImagesPathTemplate: 'successImages/levelNUM.jpg',
}

export default class App extends Component {
    state = {
        shouldShowWelcome: true,
        levelNumber: 0,
        levelQuestions: [],
        question: {},
        correctAnswersLevelTotal: 0,
    }

    //Not private!
    firebaseConfig = {
        apiKey: 'AIzaSyAw7R5jCFxn9ITUw6Ko5AziAkmpmd7YDA8',
        authDomain: 'wordsofmetal.firebaseapp.com',
        databaseURL: 'https://wordsofmetal.firebaseio.com',
        projectId: 'wordsofmetal',
        storageBucket: 'wordsofmetal.appspot.com',
        messagingSenderId: '688186911907',
        appId: '1:688186911907:web:1ce32d2dd95eb0b244a316',
    }

    constructor() {
        super()
        if (!firebase.apps.length) {
            firebase.initializeApp(this.firebaseConfig)
          }
        this.db = firebase.firestore()
    }

    async getLevelQuestions(levelNum) {
        const querySnapshot = await this.db.collection(`level${levelNum}`).get()

        if (querySnapshot.empty) {
            throw new Error('Zero documents returned from Firestore')
        }

        return querySnapshot.docs.map(doc => {
            const {correctAnswer, incorrectAnswers, lyric, youtube} = doc.data()

            const choices = incorrectAnswers.map((element, index) => {
                element.id = index + 2
                return element
            })

            correctAnswer.id = 1
            choices.push(correctAnswer)

            const question = {
                choices,
                correctAnswerId: 1,
                lyric: lyric.replace('\\n', '\n'),
                youtube
            }

            return question
        })
    }

    async downloadAndGetURLforFailureImage() {
        const response = await fetch(config.failureImage)
        if (!response.ok) {
            throw new Error(`Fetch error: ${response.status} status on ${config.failureImage}`)
        }
        const blob = await response.blob()
        if (blob.type.split('/')[0] !== 'image') {
            return ''
        }
        else {
            return URL.createObjectURL(blob)
        }
    }

    loadNextLevel() {
        const levelNumber = this.state.levelNumber + 1
        let levelQuestions
        if (levelNumber > config.numberOfLevels) {
            this.setState({
                userFinishedGame: true,
                userFinishedLevel: false,
            })
            return
        }
        this.setState({isLoading: true}, async () => {
            const successImagePath = config.successImagesPathTemplate.replace('NUM', levelNumber)
            try {
                const promises = [
                    this.getLevelQuestions(levelNumber),
                    fetch(successImagePath)
                ]
                const responses = await Promise.all(promises)
                levelQuestions = responses[0]

                if (!responses[1].ok) {
                    throw new Error(`Fetch error: ${responses[1].status} status on ${successImagePath}`)
                }
                const imageBlob = await responses[1].blob()
                if (imageBlob.type.split('/')[0] !== 'image') {
                    this.levelSuccessImageURL = ''
                } else {
                    this.levelSuccessImageURL = URL.createObjectURL(imageBlob)
                }

                if (!this.failureImageURL) {
                    this.failureImageURL = await this.downloadAndGetURLforFailureImage()
                }
            } catch (error) {
                Sentry.withScope((scope) => {
                    scope.setTag('when-loading-level', levelNumber)
                    Sentry.captureException(error)
                })
                this.setState({error})
                return
            }
            this.setState({
                levelNumber,
                levelQuestions,
                questionNumber: 0,
                correctAnswersLevelTotal: 0,
                userFinishedLevel: false,
                isLoading: false,
            }, () => this.displayNextQuestion())
        })
    }

    displayNextQuestion() {
        const questionNumber = this.state.questionNumber + 1
        const question = this.state.levelQuestions[questionNumber - 1]
        question.choices = randomiseArrayOrder(question.choices)
        this.setState({
            question,
            questionNumber,
            userAnsweredCorrectly: null,
            userSelectionId: null,
            selectionConfirmed: false,
            timerIsActive: true,
            ranOutOfTime: false,
            timerIsPaused: false,
            videoShouldDisplay: false,
            userFinishedLevel: false,
            questionScore: null
        })
    }

    nextQuestionOrLevel() {
        if (this.state.questionNumber === config.questionsPerLevel) {
            this.setState({userFinishedLevel: true})
        } else {
            //Hack to do with resetting the animation
            this.setState({timerIsActive: false}, () => this.displayNextQuestion())
        }
    }

    setRanOutOfTime() {
        this.setState({
            ranOutOfTime: true,
            questionScore: 0
        })
    }

    getLevelTimeLimitAndTimerColor() {
        return config.levelTimeLimitsAndTimerColors[this.state.levelNumber - 1]
    }

    restartLevel() {
        this.setState({
            questionNumber: 0,
            correctAnswersLevelTotal: 0,
        }, () => this.displayNextQuestion())
    }

    restartGame() {
        this.setState({
            userFinishedGame: false,
            levelNumber: 0,
            questionNumber: 0,
            correctAnswersLevelTotal: 0,
        }, () => this.loadNextLevel())
    }

    startGame() {
        this.setState({shouldShowWelcome: false}, () => this.loadNextLevel())
    }

    selectAnswer(id) {
        this.setState({userSelectionId: id})
    }

    confirmAnswer() {
        const userAnsweredCorrectly = (this.state.userSelectionId === this.state.question.correctAnswerId)
        const questionScore = userAnsweredCorrectly ? 1 : -1

        this.setState(state => ({
            selectionConfirmed: true,
            userAnsweredCorrectly,
            correctAnswersLevelTotal: state.correctAnswersLevelTotal + questionScore,
            timerIsPaused: true,
            questionScore
        }))
    }

    showVideo() {
        this.setState({videoShouldDisplay: true})
    }

    render() {
        const {lyric, choices, youtube} = this.state.question

        if (this.state.error) {
            return (
                <div style={{textAlign: 'center'}}>
                    <h1>An error occurred :((</h1>
                    <p>It's been sent to us and we're on it! Please visit again.</p>
                </div>
            )
        }

        if (this.state.shouldShowWelcome) {
            return (
                <div className="App">
                    <Welcome startGame={() => this.startGame()} />
                </div>
            )
        }

        if (this.state.isLoading) {
            return (
                <div className="App">
                    <LoadingScreen />
                </div>
            )
        }

        if (this.state.userFinishedLevel) {
            return (
                <div className="App">
                    <LevelCompleted
                        correctAnswersLevelTotal={this.state.correctAnswersLevelTotal}
                        numOfQuestions={config.questionsPerLevel}
                        levelNumber={this.state.levelNumber}
                        failureImageURL={this.failureImageURL}
                        levelSuccessImageURL={this.levelSuccessImageURL}
                        restartLevel={() => this.restartLevel()}
                        loadNextLevel={() => this.loadNextLevel()}
                    />
                </div>
            )
        }

        if (this.state.userFinishedGame) {
            return (
                <div className="App">
                    <GameCompleted restartGame={() => this.restartGame()} />
                </div>
            )
        }

        var button
        if (this.state.selectionConfirmed || this.state.ranOutOfTime) {
            button = (
                <button onClick={() => this.nextQuestionOrLevel()}>
                    Next
                </button>
            )
        } else {
            button = (
                <ConfirmAnswerBtn
                    hasUserSelected={this.state.userSelectionId !== null}
                    confirmAnswer={() => this.confirmAnswer()}
                />
            )
        }

        const [timeLimit, timerColor] = this.getLevelTimeLimitAndTimerColor() || []

        return (
            <div className="App" >
                <header className="App_header">
                    <p>Level {this.state.levelNumber}, question {this.state.questionNumber}</p>
                    <Question lyric={lyric} />
                </header>

                <Score
                    correctAnswersLevelTotal={this.state.correctAnswersLevelTotal}
                    questionScore={this.state.questionScore}
                />

                {this.state.timerIsActive &&
                    <Timer
                        timeLimit={timeLimit}
                        timerColor={timerColor}
                        timerIsActive={this.state.timerIsActive}
                        timerIsPaused={this.state.timerIsPaused}
                        ranOutOfTime={this.state.ranOutOfTime}
                        setRanOutOfTime={() => this.setRanOutOfTime()}
                    />
                }

                {/* TODO Make answers keyboard accessible */}
                <Answers
                    choices={choices}
                    userSelectionId={this.state.userSelectionId}
                    selectAnswer={(id) => this.selectAnswer(id)}
                    disabled={this.state.selectionConfirmed || this.state.ranOutOfTime}
                />

                <LevelResultMsg
                    userAnsweredCorrectly={this.state.userAnsweredCorrectly}
                    selectionConfirmed={this.state.selectionConfirmed}
                    ranOutOfTime={this.state.ranOutOfTime}
                    youtube={youtube}
                    showVideo={() => this.showVideo()}
                />

                {this.state.videoShouldDisplay && <VideoEmbed youtube={youtube}/>}

                {button}
            </div>
        )
    }
}
