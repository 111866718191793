export function randomiseArrayOrder(arr) {
    const result = []
    const randomIndexes = {}  //NEEDED ?????
    const length = arr.length

    do {
        const randomIndex = Math.floor(Math.random() * length)
        if (!(randomIndex in randomIndexes)) {
            randomIndexes[randomIndex] = true
            result.push(arr[randomIndex])
        }
    } while (result.length !== length)

    return result
}
