import React from 'react'
import './Question.scss'

export default function Question(props) {
    var i = 0
    const lines = props.lyric && props.lyric.split('\n').map(
        line => <span key={i++}>{line}<br /></span>
    )
    return (
        <div className="Question">
            <blockquote>
                {lines}
            </blockquote>
        </div>
    )
}
