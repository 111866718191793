
import React from 'react'

export default function ConfirmAnswerBtn(props) {
    return (
        <button 
            onClick={props.confirmAnswer}
            disabled={!props.hasUserSelected}    
        >
            Confirm
        </button>
    )
}


