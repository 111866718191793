import React from 'react'
import './Timer.scss'

export default function Timer(props) {
    let content

    const styleOverride = {
        animationDuration: `${props.timeLimit}s`,
        backgroundColor: `#${props.timerColor}`
    }

    if (props.timerIsPaused) {
        Object.assign(styleOverride, {animationPlayState: 'paused'})
    }

    if (props.ranOutOfTime) {
        content = <p className="Timer_outoftime">Ran out of time!</p>
    } else {
        content = (
            <div className="Timer_container">
                <div
                    // NB Because Safari, this whole component is now rendered conditional
                    // on timerisActive, so this is probably all unnecessary
                    className={props.timerIsActive ? "Timer_active" : "noop"}
                    style={props.timerIsActive ? styleOverride : {}}
                    onAnimationEnd={props.setRanOutOfTime}
                >
                </div> {/* TODO use pseudo element? */}
            </div>
        )
    }

    return (
        <div className="Timer">
            {content}
        </div>
    )
}
