import React from 'react'
import './GameCompleted.scss'

export default function GameCompleted(props) {
    return (
        <div className="GameCompleted">
            <h1>Game completed!</h1>
            
            <p>\m/ You have conquered all!! \m/</p>
            
            <p>Thanks for playing, and please remember to share the game with friends.</p>

            <p className="hashtag">&#35;WordsOfMetal</p>

            <p className="boxout">If you want some kick-ass underground metal, check out <a href="https://reduceright.com">Reduce Right</a></p>

            <button onClick={props.restartGame}>
                Restart Game
            </button>
        </div>
    )
}
