import React from 'react'
import './LevelResultMsg.scss'

export default function LevelResultMsg(props) {
    var message = props.userAnsweredCorrectly ? 'Correct!' : 'Incorrect'
    if (!props.selectionConfirmed) {
        message = ''
    }
    if (props.ranOutOfTime) {
        message = 'Out of time!'
    }

    var msgStyle = {
        color: !props.userAnsweredCorrectly || props.ranOutOfTime ? 'red' : 'green'
    }

    var watchButton = props.selectionConfirmed && props.youtube && (
        <button 
            className="LevelResultMsg_watch" 
            onClick={props.showVideo}
        >
            Watch
        </button>
    )

    return (
        <p 
            className="LevelResultMsg" 
            style={msgStyle}
        >
           {message} 
           {watchButton}
        </p>
    )
}
